<template>
  <van-tabs v-model:active="active" :before-change="beforeChange" @change="onTabChange" :lazy-render='false' sticky offset-top="10.13333vw">
    <van-tab title="待确认" name="msg" >
      <div style="background-color: #fafafa">
        <GridList title="询价单列表" port-name="tms.TmsDispatchOrderRfqCarrier.getTmsDispatchOrderRfqCarriers"
                  :request-data="{'vapp': 'vapp','confirmStatus.code':'PRICE_STATUS_TO_CONFIRM'}"
                  :dataList="dataList" style="margin: 4.26667vw;" ref="grid1">
          <!--:request-data="{'visitor.id': visitorID}"-->
          <ListCard
              v-for="item in dataList"
              :key="item.id"
              :id="item.id"
              :dispatchId="item['dispatchOrder.id']"
          >
            <div class="item" @click="onEdit(item['id'])">
              <van-image class="image" round  :src="require('../../assets/img/询价.png')"/>
              <div class="content">
                <div class="title fs16">{{ '询价单单号： '+item['dispatchOrderRfq.code'] }}</div>
                <!--            <div class="tags fs12"><span style="color: #129d67">{{'询价状态: '+ item['dispatchOrder.dispatchOrderRfq.rfqStatusDic.name'] }}</span></div>-->
                <div class="time fs12">{{ '派车单单号： '+item['dispatchOrderRfq.dispatchOrder.code'] }}</div>
                <div class="time fs12">{{ '始发地： '+item['dispatchOrderRfq.addressStart'] }}</div>
                <div class="time fs12">{{ '目的地： '+item['dispatchOrderRfq.addressEnd'] }}</div>
                <div class="time fs12">{{ '需要到达时间： '+item['dispatchOrderRfq.needReachTime'] }}</div>
              </div>
              <div class="fs12">
                <van-tag :type="getTagType(item['statusDic.code'])">{{ item['statusDic.name'] }}</van-tag>
                <van-tag plain type="primary"> {{ item['confirmStatus.name']  }}</van-tag>
              </div>
            </div>

          </ListCard>
        </GridList>
      </div>
    </van-tab>

    <van-tab title="已确认" name="material" >
      <div style="background-color: #fafafa">
        <GridList title="询价单列表" port-name="tms.TmsDispatchOrderRfqCarrier.getTmsDispatchOrderRfqCarriers"
                  :request-data="{'vapp': 'vapp','confirmStatus.code':'PRICE_STATUS_HAD_CONFIRM'}"
                  :immediate-check=true
                  :dataList="dataList" style="margin: 4.26667vw;" ref="grid2">
          <!--:request-data="{'visitor.id': visitorID}"-->
          <ListCard
              v-for="item in dataList"
              :key="item.id"
              :id="item.id"
              :dispatchId="item['dispatchOrder.id']"
          >
            <div class="item" @click="onEdit(item['id'])">
              <van-image class="image" round  :src="require('../../assets/img/询价.png')"/>
              <div class="content">
                <div class="title fs16">{{ '询价单单号： '+item['dispatchOrderRfq.code'] }}</div>
                <!--            <div class="tags fs12"><span style="color: #129d67">{{'询价状态: '+ item['dispatchOrder.dispatchOrderRfq.rfqStatusDic.name'] }}</span></div>-->
                <div class="time fs12">{{ '派车单单号： '+item['dispatchOrderRfq.dispatchOrder.code'] }}</div>
                <div class="time fs12">{{ '始发地： '+item['dispatchOrderRfq.addressStart'] }}</div>
                <div class="time fs12">{{ '目的地： '+item['dispatchOrderRfq.addressEnd'] }}</div>
                <div class="time fs12">{{ '需要到达时间： '+item['dispatchOrderRfq.needReachTime'] }}</div>
              </div>
              <div class="fs12">
                <van-tag :type="getTagType(item['statusDic.code'])">{{ item['statusDic.name'] }}</van-tag>
                <van-tag plain type="primary"> {{ item['confirmStatus.name']  }}</van-tag>
              </div>
            </div>

          </ListCard>
        </GridList>
      </div>
    </van-tab>
  </van-tabs>




</template>

<script>
import ListCard from "../../components/ListCard";
import _ from "lodash";
import {Dialog, Toast} from "vant";
import GridList from "../../components/GridList";
import CommonReserveMaterial from "@/views/vehicle/CommonReserveMaterial.vue";

export default {
  components: {ListCard, GridList},
  name: "RFQList",
  data() {
    return {
      active: '',
      count:0,
      visitorID: '',
      dataList: [],
    };
  },
  created: function () {
    var visitor = this.$sapi.storage.getItemJson('visitor');
    this.visitorID = visitor.id;

  },
  methods: {
    onAdd: function () {
      this.$router.push({name: 'vehicleReserve'})
    },
    onEdit: function (id) {
        this.$router.push({name: 'RFQReserve', params: {id: id}})
    },
    onDel: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定删除该记录？',
      })
          .then(() => {
            var option = {
              portName: 'osp.car.VehicleReserve.delVehicleReserve',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '删除成功'})
                this.$refs.grid.onRefresh();
              }
            }
            this.$sapi.callPort(option);
          })
          .catch(() => {

          });
    },
    onRead: function (dispatchId) {
            var option = {
              portName: 'tms.transport.dispatch.getEntity',
              data: {id: dispatchId},
              needLoading: false,
              successCallback: (data) => {
                this.$router.push({name: 'wfLog', params: {id: data.entities[0].wfProcess}})
              }
            }
            this.$sapi.callPort(option);
    },

    onTabChange1(index) {
      if (index === "msg") {
        return;
      }
      this.count+=1
      if(this.count>2)
        this.$refs.grid2.onRefresh();
    },
    onTabChange(index) {
      if (index === "msg") {
        this.$refs.grid1.onRefresh();
      }
      if (index === "material") {
        this.$refs.grid2.onRefresh();
      }
    },
    pageChange(){
    },
    beforeChange() {
      return true;
    },
    onChange: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定变更预约？',
      })
              .then(() => {
                var option = {
                  portName: 'osp.car.VehicleReserve.changeReserve',
                  data: {ids: [id]},
                  needLoading: false,
                  successCallback: () => {
                    this.$notify({type: 'success', message: '变更成功'});
                    this.$refs.grid.onRefresh();
                  }
                };
                this.$sapi.callPort(option);
              })
              .catch(() => {
              });
    },
    click() {
    },
    getTagType(code) {
      switch (code) {
        case 'VISITOR_RESERVE_STATUS_APPROVING':
          return 'warning';
        case 'VISITOR_RESERVE_STATUS_PASS':
          return 'success ';
        case 'VISITOR_RESERVE_STATUS_REJECT':
          return 'danger';
        default:
          return 'primary';
      }
    },
    getDateStr(d, t) {
      let date = _.isEmpty(d) ? "" : d.split(' ')[0];
      let time = _.isEmpty(t) ? "" : t.split(' ')[1];
      return date+ " " + time;
    },
  }
}

</script>

<style scoped>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.item > .title {
}

.item .status_green {
  color: #519874;
}

.item .status_red {
  color: #f83817;
}

.item > .content {
  flex: 1;
}

.item .time {
  color: #666;
}
</style>